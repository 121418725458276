import { screen } from './screen.js'; // Ready made media queries

export const responsive = {
  xs: `@media (max-width: ${screen.xsMax}px)`,
  smAndLess: `@media (max-width: ${screen.smMax}px)`,
  sm: `@media (min-width: ${screen.smMin}px) and (max-width: ${screen.smMax}px)`,
  smPlus: `@media (min-width: ${screen.smMin}px)`,
  md: `@media (min-width: ${screen.mdMin}px) and (max-width: ${screen.mdMax}px)`,
  mdPlus: `@media (min-width: ${screen.mdMin}px)`,
  lg: `@media (min-width: ${screen.lgMin}px)`,
  xlg: `@media (min-width: ${screen.xlgMin}px)`
};